@import "tailwindcss/base";
@import "tailwindcss/components";

/* purgecss start ignore */
@import "../Calendar/styles.css";
/* purgecss end ignore */

@import "tailwindcss/utilities";

:root {
  --reach-dialog: 1;
}

button {
  touch-action: manipulation;
}
