.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-off-range {
  @apply text-gray-500;
}

.rbc-off-range-bg {
  @apply bg-gray-200;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  @apply border-b;
  @apply border-solid;
  @apply border-gray-400;
  @apply px-1;
}

.rbc-header + .rbc-header {
  @apply border-l;
  @apply border-solid;
  @apply border-gray-400;
}

.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-today {
  @apply bg-gray-300;
}

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.rbc-event-content > div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rbc-event.rbc-selected {
  @apply shadow-inner;
  @apply shadow-outline;
}

.rbc-event:focus {
  outline: none;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-month-view {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}

.rbc-month-row + .rbc-month-row {
  @apply border-t;
  @apply border-solid;
  @apply border-gray-400;
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}

.rbc-day-bg + .rbc-day-bg {
  @apply border-l;
  @apply border-solid;
  @apply border-gray-400;
}

/*
=============
=============
=============
AGENDA
============+
=============
*/

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  @apply px-3;
  @apply py-2;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  @apply border-l;
  @apply border-solid;
  @apply border-gray-400;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  @apply border-b;
  @apply border-solid;
  @apply border-gray-400;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th + th {
  @apply border-l;
}

.rbc-agenda-time-cell,
.rbc-agenda-view .rbc-header:nth-child(2) {
  display: none;
}

.rbc-agenda-date-cell {
  white-space: nowrap;
  text-align: center;
}

.rbc-agenda-event-cell,
.rbc-agenda-event-cell button {
  width: 100%;
  text-align: left;
  outline: none;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-selected button {
  @apply shadow-outline;
}
